import React, { useState } from 'react';
import { Toaster, toast } from "react-hot-toast";

export default function LeadForm({ locale }) {
  const translations = {
    en: {
      title: "Book an Appointment",
      patientName: "Patient's Full Name",
      emailAddress: "Email Address",
      phoneNumber: "Phone Number",
      appointmentDate: "Appointment Date",
      appointmentTime: "Appointment Time",
      privacy: 'I have read and understood my ',
      loading: 'Processing...',
      success: 'We have received your enquiry and will get back to you shortly!',
      error: 'There was an error occurred during submission, please try again later.',
      submissionNotice: 'Our staff will contact you to confirm your appointment and answer any enquiries you may have about your first visit.',
      submit: "Submit",
      invalidated: 'Please make sure the form is filled correctly and accept our privacy policy.'
    },
    ch: {
      privacy: '我已知悉並接受我的',
      title: "預約服務",
      patientName: "姓名",
      emailAddress: "電郵",
      phoneNumber: "電話",
      appointmentDate: "預約日期",
      appointmentTime: "預約時間",
      submissionNotice: '我們將與您聯絡以確定以上預約的時間，並解答關於首次到診的查詢。',
      submit: "立即預約",
      loading: '處理中...',
      success: '我們已經收到你的預約！',
      error: '提交過程中發生錯誤，請稍後再試。',
      invalidated: '請確保所輸入的資料正確及已接受隱私權利條款。'
    }
  }
  const [inquiry, setInquiry] = useState({
    patientName: '',
    emailAddress: '',
    phoneNumber: '',
    newsletterSignUp: false,
    preferredContactMethod: '',
    visitedBefore: false,
    acceptToS: false,
    appointmentDate: '',
    appointmentTime: '',
    message: ''
  });
  return (
    <section id="leadform" className='w-full'>
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          const {
            patientName,
            emailAddress,
            phoneNumber,
            newsletterSignUp,
            preferredContactMethod,
            visitedBefore,
            acceptToS,
            appointmentDate,
            appointmentTime,
          } = inquiry;
          if (acceptToS) {
            await toast.promise(
              fetch('https://api.spinecentre.com.hk/v1/inquiries', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                  patientName,
                  emailAddress,
                  phoneNumber,
                  newsletterSignUp,
                  preferredContactMethod,
                  visitedBefore,
                  acceptToS,
                  message: `Appointment Date: ${appointmentDate}\nAppointment Time: ${appointmentTime}`,
                }),
              }),
              {
                loading: translations[locale]['loading'],
                success: translations[locale]['success'],
                error: translations[locale]['error']
              },
              {
                duration: 5000
              }
            );
            window.location.reload()
          } else {
            toast.error(
              translations[locale]['invalidated'],
              {
                duration: 10000
              }
            )
          }
        }}
        className='mx-auto mb-24 max-w-xl px-8 py-16 space-y-8 rounded-md border-t-8 border-mango bg-gray-100'>
        <h5 className="text-center font-medium text-2xl text-coconut">{translations[locale]['title']}</h5>
        <div className='flex justify-between space-x-6'>
          <fieldset className='space-y-2'>
            <label htmlFor="patientName">{translations[locale]['patientName']}*</label>
            <input
              type="text"
              id="patientName"
              name="patientName"
              className="rounded-md w-full"
              onChange={(e) => setInquiry({ ...inquiry, [e.target.name]: e.target.value })}
              required
            />
          </fieldset>
          <fieldset className='space-y-2'>
            <label htmlFor="phoneNumber">{translations[locale]['phoneNumber']}*</label>
            <input
              type="text"
              id="phoneNumber"
              name="phoneNumber"
              className="rounded-md w-full"
              autoComplete="tel"
              onChange={(e) => setInquiry({ ...inquiry, [e.target.name]: e.target.value })}
              required
            />
          </fieldset>
        </div>
        <fieldset className='flex flex-col space-y-2'>
          <label htmlFor="emailAddress">{translations[locale]['emailAddress']}*</label>
          <input
            type="text"
            id="emailAddress"
            name="emailAddress"
            className="rounded-md"
            onChange={(e) => setInquiry({ ...inquiry, [e.target.name]: e.target.value })}
            autoComplete="email"
            required
          />
        </fieldset>
        <div className='flex justify-between space-x-6'>
          <fieldset className='space-y-2'>
            <label htmlFor="appointmentDate">{translations[locale]['appointmentDate']}</label>
            <input
              type="date"
              id="appointmentDate"
              name="appointmentDate"
              className="rounded-md w-full"
              onChange={(e) => setInquiry({ ...inquiry, [e.target.name]: e.target.value })}
            />
          </fieldset>
          <fieldset className='space-y-2'>
            <label htmlFor="appointmentTime">{translations[locale]['appointmentTime']}</label>
            <input
              type="time"
              id="appointmentTime"
              name="appointmentTime"
              min="10:00"
	            step="900"
              className="rounded-md w-full"
              onChange={(e) => setInquiry({ ...inquiry, [e.target.name]: e.target.value })}
            />
          </fieldset>
        </div>
        <div className="relative flex items-start">
          <div className="flex items-center h-5">
            <input
              id="acceptToS"
              name="acceptToS"
              type="checkbox"
              checked={inquiry.acceptToS}
              onChange={(e) => setInquiry({ ...inquiry, [e.target.name]: e.target.checked })}
              className="focus:ring-mango h-4 w-4 text-mango border-gray-300 rounded"
            />
          </div>
          <div className="ml-3 text-base">
            <label htmlFor="comments" className="font-medium text-gray-700">
              {translations[locale]['privacy']}
              <a className="text-mango" href={`/${locale}/about-us/privacy-legal`}>{locale === 'en' ? ' privacy rights' : '隱私權利'}</a>{locale === 'en' ? '.' : '。'}
            </label>
          </div>
        </div>
        <fieldset className='w-full flex flex-col items-center justify-center space-y-4'>
          <label className="text-gray-500 text-sm text-center" htmlFor="submit">
            {translations[locale]['submissionNotice']}
          </label>
          <input
            type="submit"
            id="submit"
            className="bg-mango rounded-md cursor-pointer px-4 py-2 text-white"
            value={translations[locale]['submit']}
          />
        </fieldset>
        
      </form>
      <Toaster />
    </section>
  )
}
